
import {
    computed, ref, watch, defineComponent
} from 'vue';
import { checkIPAddress, checkPort, checkRelayName } from '@/methods/rules/device';
import { cloneDeep } from '@/util/common';
import { community } from '@/data';
import { ExternalRelayData } from '../types/relay.d';
import { externalRelayInitFormat, transformExternalRelayFormat } from '../utils/device-relay';

export default defineComponent({
    props: {
        deviceType: {
            type: String,
            required: true
        },
        originExternalRelay: {
            type: String,
            default: ''
        },
        projectType: {
            type: String,
            required: true
        }
    },
    setup(props) {
        const formRef = ref();

        const externalRelay = ref<ExternalRelayData>({
            ShowExternalRelay: false,
            ExtraRelaySwitch: false,
            ExtraRelayType: 'Akuvox-MK485-G2R-8J8C V3.0',
            ExtraRelayMode: 'RS485',
            Config: {
                IPAddress: '',
                Port: ''
            },
            RelayList: [
                {
                    Name: 'External Relay1',
                    RelaySwitch: true,
                    RelayFunction: 'Light'
                }
            ]
        });

        const volumeGateFunctions = ['Volume Gate-Up', 'Volume Gate-Down', 'Volume Gate-Pausing'];
        const disabledVolumeGateFunctions = ref<string[]>([]);

        const rules: Record<string, any> = {
            'Config.IPAddress': [
                { required: true, message: WordList.RuleIPAddressEmpty, trigger: 'blur' },
                { validator: checkIPAddress, trigger: 'blur' }
            ],
            'Config.Port': [
                {
                    required: true,
                    message: WordList.PleaseEnterTheFormat.format(WordList.Port),
                    trigger: 'blur'
                },
                { validator: checkPort, trigger: 'blur' }
            ]
        };

        for (let index = 0; index < 8; index++) {
            rules[`RelayList[${index}].Name`] = [
                { required: true, message: WordList.RuleRelayEmpty, trigger: 'blur' },
                { validator: checkRelayName }
            ];
        }

        const externalRelayTypeOptions = [
            { label: 'Akuvox-MK485-G2R-8J8C V3.0', value: 'Akuvox-MK485-G2R-8J8C V3.0' },
            { label: 'HF-8000', value: 'HF-8000' }
        ];

        const externalRelayModeOptions = computed(() => {
            if (externalRelay.value.ExtraRelayType === 'Akuvox-MK485-G2R-8J8C V3.0') {
                return [
                    { label: 'RS485', value: 'RS485' },
                    { label: WordList.RS485InputNonLatching, value: 'RS485+input(Non-Latching)' },
                    { label: WordList.RS485InputLatching, value: 'RS485+input(Latching)' }
                ];
            }
            return [
                { label: 'RS485', value: 'RS485' },
                { label: WordList.Ethernet, value: 'Ethernet' }
            ];
        });

        const externalRelayFunctionOptions = computed(() => [
            { label: WordList.Light, value: 'Light' },
            { label: WordList.VolumeGateUp, value: 'Volume Gate-Up', disabled: disabledVolumeGateFunctions.value.includes('Volume Gate-Up') },
            { label: WordList.VolumeGateDown, value: 'Volume Gate-Down', disabled: disabledVolumeGateFunctions.value.includes('Volume Gate-Down') },
            {
                label: WordList.VolumeGatePausing,
                value: 'Volume Gate-Pausing',
                disabled: disabledVolumeGateFunctions.value.includes('Volume Gate-Pausing')
            },
            { label: WordList.SelectDoor, value: 'Door' },
            { label: WordList.Other, value: 'Other' }
        ]);

        const enableAddExternalRelay = computed(() => {
            if (externalRelay.value.ExtraRelayType === 'Akuvox-MK485-G2R-8J8C V3.0') {
                return externalRelay.value.RelayList.length < 8;
            }
            return externalRelay.value.RelayList.length < 4;
        });

        watch(
            () => [props.originExternalRelay, community.isNew.value],
            () => {
                if (props.originExternalRelay.length > 0 && community.isNew.value) {
                    externalRelay.value = transformExternalRelayFormat({
                        type: 'backEndToFrontEnd',
                        data: props.originExternalRelay,
                        deviceType: props.deviceType
                    });
                    handleRelayFunctionChange();
                }
            },
            {
                immediate: true
            }
        );

        // 只在室内机中展示额外relay
        watch(
            () => [props.deviceType, community.isNew.value],
            () => {
                externalRelay.value.ShowExternalRelay = props.deviceType === '2' && community.isNew.value;
            },
            {
                immediate: true
            }
        );

        function handleRelayFunctionChange() {
            const filteredRelayFunctions: string[] = [];

            externalRelay.value.RelayList.forEach((relay) => {
                if (volumeGateFunctions.includes(relay.RelayFunction)) {
                    filteredRelayFunctions.push(relay.RelayFunction);
                }
            });

            disabledVolumeGateFunctions.value = filteredRelayFunctions;
        }

        function addExternalRelay() {
            const data = cloneDeep(externalRelayInitFormat);
            data.Name = `External Relay${externalRelay.value.RelayList.length + 1}`;
            externalRelay.value.RelayList.push(data);
        }

        function handleExternalRelayTypeChange(value: string) {
            if (value === 'HF-8000' && externalRelay.value.RelayList.length > 4) {
                externalRelay.value.RelayList = externalRelay.value.RelayList.slice(0, 4);
            }

            externalRelay.value.ExtraRelayMode = 'RS485';
        }

        function validate(callbackFn: (externalRelay: string) => void) {
            if (props.deviceType !== '2' || !community.isNew.value) {
                callbackFn('');
            }
            formRef.value.validate((valid: boolean) => {
                if (valid) {
                    callbackFn(
                        transformExternalRelayFormat({
                            type: 'frontEndToBackEnd',
                            data: externalRelay.value,
                            deviceType: props.deviceType
                        })
                    );
                    return true;
                }
                return false;
            });
        }

        return {
            formRef,
            externalRelay,
            rules,
            externalRelayTypeOptions,
            externalRelayModeOptions,
            externalRelayFunctionOptions,
            enableAddExternalRelay,
            handleRelayFunctionChange,
            addExternalRelay,
            handleExternalRelayTypeChange,
            validate
        };
    }
});
