import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = {
  class: "justify-between",
  style: {"margin-bottom":"10px"}
}
const _hoisted_2 = {
  class: "justify-between",
  style: {"margin-bottom":"10px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_switch = _resolveComponent("el-switch")
  const _component_custom_select = _resolveComponent("custom-select")
  const _component_custom_form_item = _resolveComponent("custom-form-item")
  const _component_custom_input = _resolveComponent("custom-input")
  const _component_custom_button = _resolveComponent("custom-button")
  const _component_custom_form = _resolveComponent("custom-form")

  return (_ctx.externalRelay.ShowExternalRelay && _ctx.projectType === 'community')
    ? (_openBlock(), _createBlock(_component_custom_form, {
        key: 0,
        ref: "formRef",
        rules: _ctx.rules,
        model: _ctx.externalRelay,
        class: "p-top-md"
      }, {
        default: _withCtx(() => [
          _createVNode("div", _hoisted_1, [
            _createVNode("span", null, [
              _createVNode("b", null, _toDisplayString(_ctx.$wordList.ExternalRelay), 1)
            ]),
            _createVNode(_component_el_switch, {
              "active-text": _ctx.$wordList.ProperAllTextOn,
              "inactive-text": _ctx.$wordList.ProperAllTextOff,
              modelValue: _ctx.externalRelay.ExtraRelaySwitch,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.externalRelay.ExtraRelaySwitch = $event)),
              "active-color": "#20a0ff",
              "inactive-color": "#fb602a",
              "active-value": true,
              "inactive-value": false
            }, null, 8, ["active-text", "inactive-text", "modelValue"])
          ]),
          (_ctx.externalRelay.ExtraRelaySwitch)
            ? (_openBlock(), _createBlock(_Fragment, { key: 0 }, [
                _createVNode(_component_custom_form_item, {
                  label: _ctx.$wordList.ExternalRelayType,
                  prop: "ExtraRelayType"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_custom_select, {
                      modelValue: _ctx.externalRelay.ExtraRelayType,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.externalRelay.ExtraRelayType = $event)),
                      options: _ctx.externalRelayTypeOptions,
                      class: "width100per",
                      onChange: _ctx.handleExternalRelayTypeChange
                    }, null, 8, ["modelValue", "options", "onChange"])
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_custom_form_item, {
                  label: _ctx.$wordList.ExternalRelayMode,
                  prop: "ExtraRelayMode"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_custom_select, {
                      modelValue: _ctx.externalRelay.ExtraRelayMode,
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_ctx.externalRelay.ExtraRelayMode = $event)),
                      options: _ctx.externalRelayModeOptions,
                      class: "width100per"
                    }, null, 8, ["modelValue", "options"])
                  ]),
                  _: 1
                }, 8, ["label"]),
                (_ctx.externalRelay.ExtraRelayMode === 'Ethernet')
                  ? (_openBlock(), _createBlock(_Fragment, { key: 0 }, [
                      _createVNode(_component_custom_form_item, {
                        label: _ctx.$wordList.TabelDeviceInHtmlIPAddress,
                        prop: "Config.IPAddress"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_custom_input, {
                            modelValue: _ctx.externalRelay.Config.IPAddress,
                            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (_ctx.externalRelay.Config.IPAddress = $event)),
                            class: "width100per"
                          }, null, 8, ["modelValue"])
                        ]),
                        _: 1
                      }, 8, ["label"]),
                      _createVNode(_component_custom_form_item, {
                        label: _ctx.$wordList.Port,
                        prop: "Config.Port"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_custom_input, {
                            modelValue: _ctx.externalRelay.Config.Port,
                            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (_ctx.externalRelay.Config.Port = $event)),
                            class: "width100per"
                          }, null, 8, ["modelValue"])
                        ]),
                        _: 1
                      }, 8, ["label"])
                    ], 64))
                  : _createCommentVNode("", true),
                (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.externalRelay.RelayList, (item, index) => {
                  return (_openBlock(), _createBlock("div", {
                    key: `externalRelay${index}`
                  }, [
                    _createVNode("div", _hoisted_2, [
                      _createVNode("span", null, _toDisplayString(_ctx.$wordList.ExternalRelay) + _toDisplayString(index + 1), 1),
                      _createVNode(_component_el_switch, {
                        "active-text": _ctx.$wordList.ProperAllTextOn,
                        "inactive-text": _ctx.$wordList.ProperAllTextOff,
                        modelValue: item.RelaySwitch,
                        "onUpdate:modelValue": ($event: any) => (item.RelaySwitch = $event),
                        "active-color": "#20a0ff",
                        "inactive-color": "#fb602a",
                        "active-value": true,
                        "inactive-value": false
                      }, null, 8, ["active-text", "inactive-text", "modelValue", "onUpdate:modelValue"])
                    ]),
                    (item.RelaySwitch)
                      ? (_openBlock(), _createBlock(_Fragment, { key: 0 }, [
                          _createVNode(_component_custom_form_item, {
                            label: _ctx.$wordList.FormRelayName,
                            prop: `RelayList[${index}].Name`
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_custom_input, {
                                modelValue: item.Name,
                                "onUpdate:modelValue": ($event: any) => (item.Name = $event)
                              }, null, 8, ["modelValue", "onUpdate:modelValue"])
                            ]),
                            _: 2
                          }, 1032, ["label", "prop"]),
                          _createVNode(_component_custom_form_item, {
                            label: _ctx.$wordList.RelayFunction,
                            prop: `RelayList[${index}].RelayFunction`
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_custom_select, {
                                modelValue: item.RelayFunction,
                                "onUpdate:modelValue": ($event: any) => (item.RelayFunction = $event),
                                options: _ctx.externalRelayFunctionOptions,
                                class: "width100per",
                                onChange: _ctx.handleRelayFunctionChange
                              }, null, 8, ["modelValue", "onUpdate:modelValue", "options", "onChange"])
                            ]),
                            _: 2
                          }, 1032, ["label", "prop"])
                        ], 64))
                      : _createCommentVNode("", true)
                  ]))
                }), 128)),
                (_ctx.enableAddExternalRelay)
                  ? (_openBlock(), _createBlock(_component_custom_button, {
                      key: 1,
                      type: "primary",
                      style: {"margin-top":"10px"},
                      onClick: _ctx.addExternalRelay
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$wordList.AddExternalRelay), 1)
                      ]),
                      _: 1
                    }, 8, ["onClick"]))
                  : _createCommentVNode("", true)
              ], 64))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["rules", "model"]))
    : _createCommentVNode("", true)
}