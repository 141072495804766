
import {
    defineComponent, onUnmounted, ref, Ref, watch
} from 'vue';
import {
    listPagination,
    RequestData, getSearch, ListHeaderItem
} from '@/components/common/list';
import deviceEditDialog, { DeviceFormData } from '@/components/view/pm/device-edit-dialog/index';
import router, { propertyBaseRouter, propertySubRouter } from '@/router';
import propertyBreadCrumb from '@/components/view/pm/bread-crumb/index';
import chooseBuildRoomInit from '@/methods/building-apt-choose';
import { access, community, device } from '@/data';
import { DeviceFormKey } from '@/components/view/pm/device-edit-dialog/all.type';
import { ChangeWordList } from '@/methods/setText';
import { limitErrorImg } from '@/methods/basicFun';
import { updateSortField, getSortImg, getInitSortField } from '@/methods/sort-table';
import { isNew, ProjectType } from '@/data/src/community';
import { transformRelayFormat, RelayItem } from '@/components/common/device-relay';
import { getProjectData } from '../composables/util';

const breadCrumb = [{ label: WordList.OrderDevice }];

interface SearchList {
    label: string;
    value: string;
}

const statusSearchList: Array<SearchList> = [
    {
        value: 'all',
        label: WordList.SelectAll
    },
    {
        value: '1',
        label: WordList.SelectOnline
    },
    {
        value: '0',
        label: WordList.SelectOffline
    }
];

const TypeSearchList: Array<SearchList> = [
    {
        label: WordList.SelectAll,
        value: 'all'
    },
    {
        label: WordList.TabelPersonDeviceInHtmlDeviceTypeStair,
        value: '0'
    },
    {
        label: WordList.TabelPersonDeviceInHtmlDeviceTypeDoor,
        value: '1'
    },
    {
        label: WordList.TabelPersonDeviceInHtmlDeviceTypeIndoor,
        value: '2'
    },
    {
        label: WordList.SelectManagementPhone,
        value: '3'
    },
    {
        label: WordList.ProperAllTextAuditCategoryAccess,
        value: '50'
    }
];

const SearchKeyList: Array<SearchList> = [
    { label: WordList.TabelPersonDeviceInHtmlMAC, value: 'MAC' },
    { label: WordList.TabelPersonDeviceInHtmlLocation, value: 'Location' }
];

export default defineComponent({
    components: {
        listPagination,
        deviceEditDialog,
        propertyBreadCrumb
    },
    props: {
        isShowBreadCrumb: {
            type: Boolean,
            default: false
        }
    },
    setup() {
        const imgItem = ref<Array<any>>([]);
        const setAllImg = (el: any) => {
            if (el) {
                limitErrorImg(el);
                imgItem.value.push(el);
            }
        };
        // 获取初始排序
        const initSortRes = getInitSortField('pmComDevice');
        const listRequestData: Ref<RequestData> = ref({
            url: community.ProjectType.value === 'community' ? 'v3/web/community/device/getListForPm' : 'v3/web/office/device/getListForPm',
            param: getSearch({
                Build: 'all',
                Room: 'all',
                Status: 'all',
                Type: 'all',
                searchKey: 'MAC',
                searchValue: '',
                Sort: community.ProjectType.value === 'community' ? initSortRes.sort : '',
                SortField: community.ProjectType.value === 'community' ? initSortRes.sortField : '',
                loading: false
            })
        });
        const updateToList = ref(0);
        const saveParamInPath = ref(false);
        function searchList() {
            updateToList.value += 1;
            saveParamInPath.value = true;
        }
        // 30s刷新一次列表，更新 DoorSensor
        let searchListInterval: number|null|any = null;
        onUnmounted(() => {
            if (searchListInterval !== null) {
                clearInterval(searchListInterval);
            }
        });
        const headers: Ref<Array<ListHeaderItem>> = ref([]);
        const projectType = community.ProjectType;
        const isSmartHome = community.smartHomeUUID;
        watch([() => projectType, () => isNew.value], () => {
            if ((community.ProjectType.value === 'community' && isNew.value) || community.ProjectType.value === 'office') {
                searchListInterval = setInterval(() => {
                    searchList();
                }, 30000);
            }
            const projectData = getProjectData()[community.ProjectType.value];
            headers.value = projectData.header;
            if (community.ProjectType.value === 'community' && isNew.value) {
                const index = headers.value.findIndex((item) => item.name === 'Status');
                headers.value.splice(index, 0, {
                    name: 'DoorSensor',
                    label: WordList.DoorSensor,
                    type: 'customize'
                });
            } else if (community.ProjectType.value === 'office') {
                const index = headers.value.findIndex((item) => item.name === 'Status');
                headers.value.splice(index, 0, {
                    name: 'DoorSensor',
                    label: WordList.DoorSensor,
                    type: 'customize'
                });
            }
            listRequestData.value.url = community.ProjectType.value === 'community'
                ? 'v3/web/community/device/getListForPm'
                : 'v3/web/office/device/getListForPm';
            searchList();
        }, {
            immediate: true,
            deep: true
        });
        const { buildOptions, aptOptions, setRoomOption } = chooseBuildRoomInit();
        const DeviceTypeIcon = {
            0: require('@/assets/image/IconDeviceTypeStair.png'),
            1: require('@/assets/image/IconDeviceTypeDoor.png'),
            2: require('@/assets/image/IconDeviceTypeIndoor.png'),
            3: require('@/assets/image/IconDeviceTypeManagement.png'),
            4: require('@/assets/image/IconDeviceTypeWall.png'),
            50: require('@/assets/image/IconDeviceTypeAccess.png')
        };

        function generateDoorSensor(data: any) {
            const isDeviceOffline = data.Status === '0';
            const isAllTwos = (str: string) => /^2+$/.test(str);
            const isPersonDevice = data.Grade === '3';
            const doorStatusIsEmpty = data.DoorRelayStatus === '' && data.DoorSeRelayStatus === '';
            if (isDeviceOffline || isPersonDevice || doorStatusIsEmpty || (isAllTwos(data.DoorRelayStatus) && isAllTwos(data.DoorSeRelayStatus))) {
                return '--';
            }
            const doorRelayStatusList = data.DoorRelayStatus.split('');
            const doorSeRelayStatusList = data.DoorSeRelayStatus.split('');
            const relay = data.Relay === '' ? [] : JSON.parse(data.Relay);
            const securityRelay = data.SecurityRelay === '' ? [] : JSON.parse(data.SecurityRelay);
            const textList: [{name: string; status: string}?][] = [[], []];
            doorRelayStatusList.forEach((item: string, index: number) => {
                if (relay[index] && relay[index].name && (item === '0' || item === '1') && relay[index].enable === 1) {
                    textList[0].push({ name: `${relay[index].name}`, status: item });
                }
            });
            doorSeRelayStatusList.forEach((item: string, index: number) => {
                if (securityRelay[index] && securityRelay[index].name && (item === '0' || item === '1') && securityRelay[index].enable === 1) {
                    textList[1].push({ name: `${securityRelay[index].name}`, status: item });
                }
            });
            if (textList[0].length === 0 && textList[1].length === 0) {
                return '--';
            }
            return textList;
        }

        watch(() => listRequestData.value.param.Build, (value) => {
            if (value === 'all') setRoomOption();
            else setRoomOption(value as string);
        });

        // dialog部分开始
        const isShowDeviceEditDialog = ref(false);
        const initForm: Ref<Record<string, any>> = ref({
            UnitName: '',
            RoomName: '',
            Location: '',
            ID: '',
            ExtraRelay: '',
            Relay: [] as RelayItem[],
            SecurityRelay: [] as RelayItem[],
            Type: '',
            StairShow: '',
            Grade: '',
            MAC: '',
            DepartmentName: '',
            AllowEndUserMonitor: '1'
        });
        const edit = (listItem: Record<string, any>) => {
            Object.keys(initForm.value).forEach((item) => {
                const key = item;

                if (listItem[key] !== null) {
                    if (key === 'Relay' || key === 'SecurityRelay') {
                        let role = '';
                        if (ProjectType.value === 'community' && isNew.value) {
                            role = 'pmNewCommunity';
                        } else if (ProjectType.value === 'office') {
                            role = 'pmOffice';
                        } else {
                            role = 'pmOldCommunity';
                        }
                        initForm.value[key] = transformRelayFormat(
                            {
                                data: listItem[key],
                                type: 'backEndToFrontEnd',
                                deviceType: listItem.Type,
                                role,
                                deviceCate: listItem.Grade,
                                relayType: key === 'Relay' ? 'relay' : 'securityRelay'
                            }
                        );
                        console.log(initForm.value[key]);
                    } else {
                        initForm.value[key] = listItem[key];
                    }
                } else {
                    initForm.value[key] = '';
                }
                // if (community.isNew.value && listItem.Type !== device.indoor && listItem.Type !== device.managementPhone) {
                //     initForm.value.SecurityRelay = listItem.SecurityRelay;
                // }
            });
            // console.log('initForm', initForm, listItem);
            isShowDeviceEditDialog.value = true;
        };
            // dialog部分结束

        const info = (listItem: DeviceFormData) => {
            router.push(`/${propertyBaseRouter}/${propertySubRouter.deviceInfo}?ID=${listItem.ID}`);
        };

        // 新增屏保需求
        function goToScreenSaver() {
            router.push(`/${propertyBaseRouter}/${propertySubRouter.screenSaver}`);
        }

        // 获取排序方式, 更新表格数据
        function getSortRes(table: string, sortField: string) {
            const {
                sort
            } = updateSortField(table, sortField);
            listRequestData.value.param.Sort = sort;
            listRequestData.value.param.SortField = sortField;
            updateToList.value += 1;
        }

        return {
            headers,
            listRequestData,
            isShowDeviceEditDialog,
            edit,
            info,
            router,
            breadCrumb,
            buildOptions,
            aptOptions,
            setRoomOption,
            statusSearchList,
            TypeSearchList,
            SearchKeyList,
            DeviceTypeIcon,
            access,
            saveParamInPath,
            searchList,
            updateToList,
            initForm,
            ChangeWordList,
            projectType,
            isSmartHome,
            setAllImg,
            goToScreenSaver,
            getSortImg,
            getSortRes,
            generateDoorSensor
        };
    }
});
