import { ListHeaderItem } from '@/components/common/list';

const titleType = 1;
const labelType = 2;
const labelCustomize = 3;
const publicType = 4;

// 办公、社区设备列表、详情区分
type ProjectDataType = {
    [key in ProjectType]: {
        header: Array<ListHeaderItem>;
        detailForm: any;
        deviceType: {
            [key in number]: string;
        };
    };
};
const getProjectData = () => {
    const communityHeader: Array<ListHeaderItem> = [
        {
            name: 'UnitName',
            label: WordList.DeviceDetailDetailUnitName
        }, {
            name: 'RoomName',
            label: WordList.RDeviceSearchLabelRoomName
        }, {
            name: 'Location',
            label: WordList.TabelPersonDeviceInHtmlLocation,
            headerType: 'customize'
        }, {
            name: 'Name',
            label: WordList.TabelPersonUserInHtmlName
        }, {
            name: 'MAC',
            label: WordList.TabelPersonDeviceInHtmlMAC
        }, {
            name: 'Type',
            label: WordList.TabelPersonDeviceInHtmlDeviceTypeLabel,
            type: 'customize'
        }, {
            name: 'Status',
            label: WordList.MulListUpdateListTanleStatus,
            type: 'customize'
        }, {
            name: 'LastConnection',
            label: WordList.PersonaldeviceListTanleBindingTime,
            headerType: 'customize'
        }
    ];
    const officeHeader: Array<ListHeaderItem> = [
        {
            name: 'DepartmentName',
            label: WordList.ProperAllTextDepartment
        }, {
            name: 'Owner',
            label: WordList.TabelPersonDeviceInHtmlOwner
        }, {
            name: 'Location',
            label: WordList.TabelPersonDeviceInHtmlLocation
        }, {
            name: 'MAC',
            label: WordList.TabelPersonDeviceInHtmlMAC
        }, {
            name: 'Type',
            label: WordList.TabelPersonDeviceInHtmlDeviceTypeLabel,
            type: 'customize'
        }, {
            name: 'Status',
            label: WordList.MulListUpdateListTanleStatus,
            type: 'customize'
        }, {
            name: 'LastConnection',
            label: WordList.ProperAllTextBindTime
        }
    ];
    const communityDetailForm = [
        {
            label: WordList.DeviceDetailDetailBasic,
            text: '',
            type: titleType,
            name: 'Basic'
        }, {
            label: WordList.ProperAllTextPermissionBuilding,
            text: '',
            type: publicType,
            name: 'BuildNames'
        }, {
            label: WordList.DeviceDetailDetailUnitName,
            text: '',
            type: labelType,
            name: 'UnitName'
        }, {
            label: WordList.DeviceDetailDetailRoomName,
            text: '',
            type: labelType,
            name: 'RoomName'
        }, {
            label: WordList.TabelPersonDeviceInHtmlLocation,
            text: '',
            type: labelType,
            name: 'Location'
        }, {
            label: WordList.TabelPersonDeviceInHtmlMAC,
            text: '',
            type: labelType,
            name: 'MAC'
        }, {
            label: WordList.MulListUpdateListTanleAccount,
            text: '',
            type: labelType,
            name: 'SipAccount'
        }, {
            label: WordList.DeviceListTanleLastConnected,
            text: '',
            type: labelType,
            name: 'LastConnection'
        }, {
            label: WordList.TabelKeyInHtmlType,
            text: '',
            type: labelCustomize,
            name: 'Grade'
        }, {
            label: WordList.TabelPersonDeviceInHtmlOwner,
            text: '',
            type: labelType,
            name: 'Owner'
        }, {
            label: WordList.DeviceDetailDetailProduct,
            text: '',
            type: titleType,
            name: 'Product'
        }, {
            label: WordList.DeviceDetailDetailFirmware,
            text: '',
            type: labelType,
            name: 'Firmware'
        }, {
            label: WordList.DeviceDetailDetailHardware,
            text: '',
            type: labelType,
            name: 'Hardware'
        }, {
            label: WordList.DeviceDetailDetailNetwork,
            text: '',
            type: titleType,
            name: 'Network'
        }, {
            label: WordList.DeviceDetailDetailIPAddress,
            text: '',
            type: labelType,
            name: 'IPAddress'
        }, {
            label: WordList.DeviceDetailDetailSubnetMask,
            text: '',
            type: labelType,
            name: 'SubnetMask'
        }, {
            label: WordList.DeviceDetailDetailGateway,
            text: '',
            type: labelType,
            name: 'Gateway'
        }, {
            label: WordList.DeviceDetailDetailPrimaryDNS,
            text: '',
            type: labelType,
            name: 'PrimaryDNS'
        }, {
            label: WordList.DeviceDetailDetailSecondaryDNS,
            text: '',
            type: labelType,
            name: 'SecondaryDNS'
        }
    ];
    const officeDetailForm = [
        {
            label: WordList.DeviceDetailDetailBasic,
            text: '',
            type: titleType,
            name: 'Basic'
        }, {
            label: WordList.ProperAllTextPermissionBuilding,
            text: '',
            type: publicType,
            name: 'DepartmentNames'
        }, {
            label: WordList.ProperAllTextDepartment,
            text: '',
            type: labelType,
            name: 'UnitName'
        }, {
            label: WordList.TabelPersonDeviceInHtmlLocation,
            text: '',
            type: labelType,
            name: 'Location'
        }, {
            label: WordList.TabelPersonDeviceInHtmlMAC,
            text: '',
            type: labelType,
            name: 'MAC'
        }, {
            label: WordList.MulListUpdateListTanleAccount,
            text: '',
            type: labelType,
            name: 'SipAccount'
        }, {
            label: WordList.DeviceListTanleLastConnected,
            text: '',
            type: labelType,
            name: 'LastConnection'
        }, {
            label: WordList.TabelKeyInHtmlType,
            text: '',
            type: labelCustomize,
            name: 'Grade'
        }, {
            label: WordList.TabelPersonDeviceInHtmlOwner,
            text: '',
            type: labelType,
            name: 'Owner'
        }, {
            label: WordList.DeviceDetailDetailProduct,
            text: '',
            type: titleType,
            name: 'Product'
        }, {
            label: WordList.DeviceDetailDetailFirmware,
            text: '',
            type: labelType,
            name: 'Firmware'
        }, {
            label: WordList.DeviceDetailDetailHardware,
            text: '',
            type: labelType,
            name: 'Hardware'
        }, {
            label: WordList.DeviceDetailDetailNetwork,
            text: '',
            type: titleType,
            name: 'Network'
        }, {
            label: WordList.DeviceDetailDetailIPAddress,
            text: '',
            type: labelType,
            name: 'IPAddress'
        }, {
            label: WordList.DeviceDetailDetailSubnetMask,
            text: '',
            type: labelType,
            name: 'SubnetMask'
        }, {
            label: WordList.DeviceDetailDetailGateway,
            text: '',
            type: labelType,
            name: 'Gateway'
        }, {
            label: WordList.DeviceDetailDetailPrimaryDNS,
            text: '',
            type: labelType,
            name: 'PrimaryDNS'
        }, {
            label: WordList.DeviceDetailDetailSecondaryDNS,
            text: '',
            type: labelType,
            name: 'SecondaryDNS'
        }
    ];
    const communityDeviceType = {
        1: WordList.RDeivicePublicAreaDevice,
        2: WordList.RDeiviceBuildDevice,
        3: WordList.RDeiviceUserDevice
    };
    const officeDeviceType = {
        1: WordList.RDeivicePublicAreaDevice,
        2: WordList.DepartmentDevice,
        3: WordList.PeopleDevice
    };
    const projectData: ProjectDataType = {
        community: {
            header: communityHeader,
            detailForm: communityDetailForm,
            deviceType: communityDeviceType
        },
        office: {
            header: officeHeader,
            detailForm: officeDetailForm,
            deviceType: officeDeviceType
        }
    };
    return projectData;
};

export default null;
export {
    getProjectData
};
