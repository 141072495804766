
import dialogShell from '@/components/common/dialog-shell/index.vue';
import HttpRequest from '@/util/axios.config';
import deviceRelay, { initRelayDataAndFormat, transformRelayFormat, RelayItem } from '@/components/common/device-relay';
import {
    defineComponent, ref, Ref, PropType, reactive, computed
} from 'vue';
import { DeviceFormData } from '@/components/view/pm/device-edit-dialog/all.type';
import { checkSpecialCharacters } from '@/methods/rules/device';
import relay from '@/components/view/common/relay';
import { ChangeWordList } from '@/methods/setText';
import { device } from '@/data';
import { isNew, ProjectType } from '@/data/src/community';
import externalRelay from '@/components/common/device-relay/components/external-relay.vue';
import { omit } from '@/util/common';

export default defineComponent({
    components: {
        dialogShell,
        deviceRelay,
        externalRelay
    },
    props: {
        initForm: {
            type: Object as PropType<DeviceFormData>,
            required: true
        },
        projectType: {
            type: String as PropType<ProjectType>,
            default: 'community'
        }
    },
    setup(props, { emit }) {
        const deviceEditFormRef: Ref<any> = ref();
        const formData = reactive({ ...props.initForm, relayInitFormat: {}, securityRelayInitFormat: {} });
        const rules = {
            Location: [{
                required: true,
                message: WordList.RuleLocationEmpty,
                trigger: 'change'
            }, {
                validator: checkSpecialCharacters,
                trigger: 'change'
            }]
        };
        let role = '';
        if (ProjectType.value === 'community' && isNew.value) {
            role = 'pmNewCommunity';
        } else if (ProjectType.value === 'office') {
            role = 'pmOffice';
        } else {
            role = 'pmOldCommunity';
        }

        // 只在多住户门口机、单住户门口机、门禁展示监控控制按钮
        const showMonitorControl = computed(() => isNew.value && (formData.Type === '0' || formData.Type === '1' || formData.Type === '50'));

        console.log(ProjectType.value, isNew.value, formData.Type as string, role, formData.Grade as string);
        const initRelayDataAndFormatData = initRelayDataAndFormat(formData.Type as string, role, formData.Grade as string);
        console.log(initRelayDataAndFormatData);
        formData.relayInitFormat = initRelayDataAndFormatData.relayInitFormat;
        formData.securityRelayInitFormat = initRelayDataAndFormatData.securityRelayInitFormat;
        const close = () => {
            emit('close');
        };
        const accessGroupForRelaySchedule = ref<[{ID: string;Name: string}][]>([]);
        function generateAccessGroup(index: string): Promise<any> {
            const r = new Promise((resolve) => {
                HttpRequest.get(
                    ProjectType.value === 'community'
                        ? 'v3/web/community/access/getListByMac'
                        : 'v3/web/office/access/getListByMac',
                    {
                        MAC: formData.MAC,
                        Relay: index,
                        SecurityRelay: ''
                    }, (res: {ID: string;Name: string}[]) => resolve(res)
                );
            });
            return r;
        }
        if (['0', '1', '2', '50'].includes(formData.Type as string)) {
            generateAccessGroupList();
        }
        function generateAccessGroupList(length?: number) {
            const relayListLenArr: number[] = [];
            const len = length || formData.Relay.length;
            for (let i = 0; i < len; i++) {
                relayListLenArr.push(i);
            }
            relayListLenArr.forEach(async (item, index) => {
                const res: {data: {ID: string;Name: string}[]} = await generateAccessGroup(item.toString());
                // @ts-ignore
                accessGroupForRelaySchedule.value[index] = res.data;
                console.log('formData.Relay[index].schedule.access', JSON.stringify(formData.Relay[index].schedule.access));
                formData.Relay[index].schedule.access = formData.Relay[index].schedule.access.filter((f) => {
                    const isExist = res.data.some((s) => s.ID === f);
                    if (isExist) {
                        return f;
                    }
                });
                console.log('formData.Relay[index].schedule.access11', JSON.stringify(formData.Relay[index].schedule.access));
            });
        }
        const submit = (relayValue: any[], securityRelayValue: any[], externalRelayValue?: string) => {
            const param = { ...formData };
            param.Relay = transformRelayFormat({ data: relayValue, type: 'frontEndToBackEnd' }) as any;
            param.SecurityRelay = transformRelayFormat({ data: securityRelayValue, type: 'frontEndToBackEnd' }) as any;
            param.ExtraRelay = externalRelayValue || '';
            HttpRequest.post(
                ProjectType.value === 'community'
                    ? 'v3/web/community/device/editForPm'
                    : 'v3/web/office/device/editForPm',
                ProjectType.value === 'community' && param.Type === '2' && isNew.value ? param : omit(param, ['ExtraRelay']),
                () => {
                    emit('success');
                    close();
                }
            );
        };
        const commit = ref(0);

        const deviceRelayRef = ref();
        const externalDeviceRelayRef = ref();

        const checkValid = () => {
            deviceEditFormRef.value.validate((valid: boolean) => {
                deviceRelayRef.value.validate((relayValue: RelayItem[], securityRelayValue: RelayItem[]) => {
                    if (props.projectType === 'community') {
                        externalDeviceRelayRef.value.validate((externalRelayValue: string) => {
                            if (valid) {
                                if (formData.Type === device.managementPhone) {
                                    submit([], []);
                                } else if (formData.Type === device.indoor) {
                                    submit(relayValue, securityRelayValue, externalRelayValue);
                                } else {
                                    submit(relayValue, securityRelayValue);
                                }
                            }
                        });
                    } else if (valid) {
                        if (formData.Type === device.managementPhone) {
                            submit([], []);
                        } else {
                            submit(relayValue, securityRelayValue);
                        }
                    }
                });
            });
        };

        return {
            formData,
            showMonitorControl,
            checkValid,
            submit,
            close,
            deviceEditFormRef,
            accessGroupForRelaySchedule,
            generateAccessGroupList,
            rules,
            commit,
            deviceRelayRef,
            externalDeviceRelayRef,
            ChangeWordList,
            isNew,
            ProjectType
        };
    }
});

